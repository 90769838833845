.computer-training {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 20px;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.header {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.header-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 10px;
    animation: imageFade 2s ease-in-out;
}

@keyframes imageFade {
    from { opacity: 0; transform: scale(1.05); }
    to { opacity: 1; transform: scale(1); }
}

.header h1 {
    font-size: 2.5em;
    color: #541936;
    margin: 10px 0;
}

.header p {
    font-size: 1.2em;
    color: #666;
    animation: fadeIn 1.5s ease-in-out;
}

.program-details, .curriculum, .success-stories, .call-to-action {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #541936;
    font-size: 2em;
    margin-bottom: 10px;
}

.curriculum-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.curriculum-item {
    flex: 1 1 300px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.curriculum-item:hover {
    transform: scale(1.05);
}

.curriculum-item h3 {
    color: #541936;
}

.success-stories .story {
    margin-bottom: 10px;
    font-style: italic;
    border-left: 3px solid #541936;
    padding-left: 10px;
}

.call-to-action a {
    color: #541936;
    text-decoration: none;
}

.call-to-action a:hover {
    text-decoration: underline;
}


.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #541936;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.2em;
    display: none;
    z-index: 1000;
}

.back-to-top:hover {
    background-color: #333;
}

@media (max-width: 768px) {
    .curriculum-content {
        flex-direction: column;
    }

    .curriculum-item {
        flex: 1 1 100%;
    }
}
