.how-you-can-help {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro, .ways-to-help, .impact, .contact {
    margin-bottom: 40px;
    text-align: center;
}

.intro h1 {
    font-size: 2.5em;
    color: #541936; /* Theme color */
    margin-bottom: 20px;
    position: relative;
}

/* .intro h1::after {
    content: '';
    width: 60px;
    height: 4px;
    background-color: #541936;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
} */

.intro p {
    font-size: 1.2em;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

.ways-to-help {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.way {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
}

.way:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.way::before {
    content: '';
    width: 60px;
    height: 4px;
    background-color: #541936;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
}

.way h2 {
    font-size: 1.8em;
    color: #541936; /* Theme color */
    margin-bottom: 15px;
}

.way p {
    font-size: 1.1em;
    margin-bottom: 20px;
    line-height: 1.5;
}

.button {
    background-color: #541936; /* Theme color */
    color: white;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1em;
    display: inline-block;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
    background-color: #3a1225; /* Darker shade for hover */
    transform: translateY(-5px);
}

.contact-info p {
    margin: 5px 0;
    font-size: 1.1em;
}

@media (max-width: 768px) {
    .intro h1 {
        font-size: 2em;
    }

    .intro p {
        font-size: 1em;
    }

    .way h2 {
        font-size: 1.5em;
    }

    .way p {
        font-size: 1em;
    }

    .button {
        font-size: 0.9em;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .intro h1 {
        font-size: 1.8em;
    }

    .intro p {
        font-size: 0.9em;
    }

    .way h2 {
        font-size: 1.3em;
    }

    .way p {
        font-size: 0.9em;
    }

    .button {
        font-size: 0.8em;
        padding: 8px 16px;
    }
}
