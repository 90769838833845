.sewing-container {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
}

.sewing-header {
  background-image: url('https://highlandpost.com/wp-content/uploads/2023/03/Pic-2-scaled.jpg');
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0 20px;
}

.sewing-header-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  margin: 0 auto;
}

.sewing-intro {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px;
  background-color: #f0f0f0;
}

.sewing-intro-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.sewing-intro-text {
  flex: 1 1 100%;
  max-width: 50%;
  padding: 20px;
}

.sewing-intro-image {
  flex: 1 1 100%;
  max-width: 50%;
  text-align: center;
}

.sewing-intro-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.sewing-programs {
  padding: 40px 20px;
  background-color: #fff;
  text-align: center;
}

.sewing-programs h2 {
  margin-bottom: 20px;
}

.sewing-program-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.sewing-program-item {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 300px;
}

.sewing-program-item:hover {
  transform: translateY(-10px);
}

.sewing-program-item img {
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.sewing-success-stories {
  padding: 40px 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.sewing-carousel {
  max-width: 800px;
  margin: 0 auto;
}

.sewing-testimonial {
  font-style: italic;
  margin: 20px 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sewing-testimonial span {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.sewing-approach {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 20px;
  background-color: #fff;
}

.sewing-approach-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.sewing-approach-text {
  flex: 1 1 100%;
  max-width: 50%;
  padding: 20px;
}

.sewing-approach-image {
  flex: 1 1 100%;
  max-width: 50%;
  text-align: center;
}

.sewing-approach-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.sewing-cta {
  padding: 40px 20px;
  background-color: #7CA24A;
  color: white;
  text-align: center;
}

.sewing-cta h2 {
  margin-bottom: 20px;
}

.sewing-cta-button {
  background-color: #fff;
  color: #4CAF50;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
}

.sewing-cta-button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .sewing-intro-text,
  .sewing-intro-image,
  .sewing-approach-text,
  .sewing-approach-image {
    max-width: 100%;
    flex: 1 1 100%;
  }
  
  .sewing-header {
    height: 40vh;
  }

  .sewing-program-item {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .sewing-header-content h1 {
    font-size: 24px;
  }

  .sewing-header-content p {
    font-size: 16px;
  }

  .sewing-intro-text {
    font-size: 16px;
  }

  .sewing-intro-image img {
    width: 100%;
  }

  .sewing-program-item {
    width: 100%;
    padding: 15px;
  }

  .sewing-program-item img {
    max-width: 150px;
  }

  .sewing-cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}