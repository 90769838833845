.candle-wrapper {
    font-family: 'Verdana', sans-serif;
    color: #444;
    margin: 0;
    padding: 0;
    background-color: #fdfdfd;
  }
  
  .candle-hero {
    background-image: url('https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/2017/10/14202932/silence-2-%5E.jpg');
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  
  .candle-hero-text h1 {
    font-size: 2.5em;
    margin: 0;
  }
  
  .candle-hero-text p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .candle-why {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    background-color: #fafafa;
  }
  
  .candle-why-content {
    flex: 1 1 100%;
    max-width: 60%;
    padding: 20px;
  }
  
  .candle-why-image {
    flex: 1 1 100%;
    max-width: 40%;
    text-align: center;
  }
  
  .candle-why-image img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  .candle-highlights {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .candle-highlights-title h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .candle-highlights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .candle-highlight-item {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .candle-highlight-item:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .candle-highlight-item h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
  }
  
  .candle-testimonials {
    padding: 40px 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  .candle-testimonial-carousel {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .candle-testimonial {
    background-color: #fff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  
  .candle-testimonial span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .candle-get-involved {
    padding: 40px 20px;
    background-color: #7CA24A;
    color: white;
    text-align: center;
  }
  
  .candle-get-involved h2 {
    margin-bottom: 20px;
    font-size: 2em;
  }
  
  .candle-cta-button {
    background-color: #fff;
    color: #7CA24A;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .candle-cta-button:hover {
    background-color: #e7e7e7;
  }
  
  @media (max-width: 768px) {
    .candle-why-content,
    .candle-why-image {
      max-width: 100%;
      flex: 1 1 100%;
      text-align: center;
    }
  
    .candle-hero {
      height: 50vh;
    }
  }
  