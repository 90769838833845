.home-management {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 20px;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.header {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.header-image {
    width: 100%;
    height: auto;
    max-height: 510px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 10px;
    animation: imageFade 2s ease-in-out;
}

@keyframes imageFade {
    from { opacity: 0; transform: scale(1.05); }
    to { opacity: 1; transform: scale(1); }
}

.header h1 {
    font-size: 2.5em;
    color: #541936;
    margin: 10px 0;
}

.header p {
    font-size: 1.2em;
    color: #666;
    animation: fadeIn 1.5s ease-in-out;
}

/* Program Details */
.program-details, .curriculum, .quote-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #541936;
    font-size: 2em;
    margin-bottom: 10px;
}

/* Curriculum Section */
.curriculum-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.curriculum-item {
    flex: 1 1 300px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.curriculum-item:hover {
    transform: scale(1.05);
}

.curriculum-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.curriculum-item h3 {
    color: #541936;
    margin-bottom: 10px;
}

.curriculum-item p {
    font-size: 1rem;
}

/* Quote Section */
.quote-section {
    background-color: #541936;
    padding: 20px;
    color: #fff;
    text-align: center;
    font-style: italic;
    border-radius: 10px;
    max-width: 1000px;
    margin: 0 auto;
}

.quote-section blockquote {
    font-size: 1.5rem;
    margin: 0;
}
