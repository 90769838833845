.objectives-container {
    font-family: 'Roboto', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .objectives-header {
    background-image: url('/src/components//img/object1.jpeg');
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
  }
  
  .objectives-header-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 10px;
  }
  
  .objectives-list {
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .objectives-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .objectives-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    transition: transform 0.3s ease;
    text-align: center;
  }
  
  .objectives-item img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .objectives-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .objectives-item p {
    font-size: 16px;
    color: #666;
  }
  
  .objectives-item:hover {
    transform: translateY(-5px);
  }
  
  .objectives-cta {
    background-color: #7CA24A;
    color: white;
    padding: 40px 20px;
    text-align: center;
  }
  
  .objectives-cta h2 {
    margin-bottom: 20px;
  }
  
  .objectives-cta-button {
    background-color: #fff;
    color: #7CA24A;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .objectives-cta-button:hover {
    background-color: #eee;
  }
  
  @media (max-width: 768px) {
    .objectives-header {
      height: 40vh;
    }
  
    .objectives-item {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  