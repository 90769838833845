/* Documents.css */
.documents-container {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    margin: 0 auto;
    transform: scale(1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: fadeInUp 1s ease-out;
  }
  
  .documents-container:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  
  .documents-container h1 {
    font-size: 2.5em;
    color: #541936; /* Your theme color */
    margin-bottom: 20px;
    animation: fadeIn 1s ease-out;
  }
  
  .documents-container p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
    animation: fadeIn 1.5s ease-out;
  }
  
  .documents-container button {
    background-color: #541936; /* Your theme color */
    color: #fff;
    border: none;
    padding: 15px 25px;
    font-size: 1.1em;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    animation: bounceIn 1s ease-out;
  }
  
  .documents-container button:hover {
    background-color: #3c0f24; /* Darker shade of your theme color for hover effect */
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .documents-container button:focus {
    outline: none;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounceIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  