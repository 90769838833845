.primary10-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .primary10-header {
    background-image: url('/src/components/img/primary1.jpg');
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 0 20px;
  }
  
  .primary10-header-content {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .primary10-intro {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .primary10-intro-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .primary10-intro-text {
    flex: 1 1 100%;
    max-width: 50%;
    padding: 20px;
  }
  
  .primary10-intro-image {
    flex: 1 1 100%;
    max-width: 50%;
    text-align: center;
  }
  
  .primary10-intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .primary10-curriculum {
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .primary10-curriculum h2 {
    margin-bottom: 20px;
  }
  
  .primary10-curriculum-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .primary10-curriculum-item {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .primary10-curriculum-item:hover {
    transform: translateY(-10px);
  }
  
  .primary10-curriculum-item img {
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .primary10-success-stories {
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .primary10-carousel {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .primary10-testimonial {
    font-style: italic;
    margin: 20px 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .primary10-testimonial span {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .primary10-approach {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    background-color: #fff;
  }
  
  .primary10-approach-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .primary10-approach-text {
    flex: 1 1 100%;
    max-width: 50%;
    padding: 20px;
  }
  
  .primary10-approach-image {
    flex: 1 1 100%;
    max-width: 50%;
    text-align: center;
  }
  
  .primary10-approach-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .primary10-cta {
    padding: 40px 20px;
    background-color: #7CA24A;
    color: white;
    text-align: center;
  }
  
  .primary10-cta h2 {
    margin-bottom: 20px;
  }
  
  .primary10-cta-button {
    background-color: #fff;
    color: #7CA24A;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .primary10-cta-button:hover {
    background-color: #eee;
  }
  
  @media (max-width: 768px) {
    .primary10-intro-text,
    .primary10-intro-image,
    .primary10-approach-text,
    .primary10-approach-image {
      max-width: 100%;
      flex: 1 1 100%;
    }
    
    .primary10-header {
      height: 40vh;
    }
  }
  