.vision-mission-container {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .vision-mission-header {
    background-image: url('/src/components/img/AboutBannerr.jpg');
    background-size: cover;
    background-position: center;
    height: 84vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 0 20px;
  }
  
  .vision-mission-header-content {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .vision-section, .mission-section {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .vision-content, .mission-content {
    flex: 1 1 100%;
    max-width: 50%;
    padding: 20px;
  }
  
  .vision-image, .mission-image {
    flex: 1 1 100%;
    max-width: 50%;
    text-align: center;
  }
  
  .vision-image img, .mission-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .vision-mission-cta {
    padding: 40px 20px;
    background-color: #7CA24A;
    color: white;
    text-align: center;
  }
  
  .vision-mission-cta h2 {
    margin-bottom: 20px;
  }
  
  .vision-mission-cta-button {
    background-color: #fff;
    color: #7CA24A;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .vision-mission-cta-button:hover {
    background-color: #eee;
  }
  
  @media (max-width: 768px) {
    .vision-content, .mission-content, .vision-image, .mission-image {
      max-width: 100%;
      flex: 1 1 100%;
    }
  
    .vision-mission-header {
      height: 40vh;
    }
  }
  