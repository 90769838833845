.container {
   
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Distribute items evenly */
    gap: 20px;
  }
  
  
  .box {
    border-radius: 10px;
    padding: 60px;
    background-color: #f4f4f4;
    flex: 1; /* Allow boxes to grow equally */
  }
  
  @media (max-width: 768px) {
    .box {
      flex-basis: calc(50% - 20px); /* Two boxes per row */
    }
  }
  
  @media (max-width: 576px) {
    .box {
      flex-basis: calc(100% - 20px); /* One box per row */
    }
  }
  .container {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Distribute items evenly */
    gap: 20px;
  }
  
  .box {
    border-radius: 10px;
    padding: 50px;
    background-color: #f4f4f4;
    flex: 1; /* Allow boxes to grow equally */
    max-width: 700px; /* Set maximum width for the boxes */
  }
  
  @media (max-width: 768px) {
    .box {
      flex-basis: calc(50% - 20px); /* Two boxes per row */
    }
  }
  
  @media (max-width: 576px) {
    .box {
      flex-basis: calc(100% - 20px); /* One box per row */
    }
  }
  .box {
    border-radius: 10px;
    padding: 50px;
    background-color: #f4f4f4;
    flex: 1; /* Allow boxes to grow equally */
    max-width: 700px; /* Set maximum width for the boxes */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  }
  .container {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  .box {
    border-radius: 10px;
    padding: 50px;
    background-color: #f4f4f4;
    flex: 1;
    max-width: 700px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media (max-width: 768px) {
    .box {
      flex-basis: calc(50% - 20px);
    }
  }
  
  @media (max-width: 576px) {
    .box {
      flex-basis: calc(100% - 20px);
    }
  }
  .container {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Distribute items evenly */
    gap: 20px;
}

.box {
    border-radius: 10px;
    padding: 50px;
    background-color: #f4f4f4;
    flex: 1; /* Allow boxes to grow equally */
    max-width: 700px; /* Set maximum width for the boxes */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.animate {
    opacity: 1;
    transform: translateY(0);
}

/* Responsive styles for tablets and smaller screens */
@media (max-width: 768px) {
    .box {
        flex-basis: calc(50% - 20px); /* Two boxes per row */
    }
}

@media (max-width: 576px) {
    .box {
        flex-basis: calc(100% - 20px); /* One box per row */
    }
}

/* Responsive styles specifically for iPhone 14 */
@media (max-width: 390px) { /* iPhone 14 screen width */
    .box {
        padding: 30px; /* Adjust padding */
        flex-basis: calc(100% - 20px); /* One box per row */
        max-width: 100%; /* Remove max-width */
    }

    .container {
        padding-top: 20px; /* Adjust container padding */
    }
}
