.Doctor-text-TM h1 {
    color: #541936;
    font-size: 26px;
  }
  .Doctor-text-TM h4 {
    color: #232324;
    font-size: 18px;
  }
  
  .Doctor-text-TM p {
    color: #333333;
    font-size: 15px;
  }
  
  /* .card-TM {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
  } */
  
  /* .card-TM:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  } */
  
  .card-TM {
    position: relative;
    overflow: hidden;
  }
  
  .card-TM img {
    
    transition: transform 0.3s ease-in-out;
    border-radius: 5%;
    width:100%;
    height: 1005;
  }
  
  .card-TM:hover img {
    
    transform: scale(1.10);
  }
  
  .yellow-line-TM {
    height: 1px;
    border-radius: 10px;
    width: 70px;
    background-color: #f4b959;
    margin: 1 auto;
    border-width: 2px 0 0;
    border-color: #f4b959;
  }
  
  .card-TM-container {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .card-TM-container.animate {
    opacity: 1;
    transform: translateY(0);
  }
  .card-TM-container {
    margin-bottom: 20px; /* Adds space below each card */
  }
  
  .card-TM {
    margin: 10px; /* Adds space around each card */
    padding:40px; /* Optional: Adds padding inside each card */
   
    border-radius: 8px; /* Optional: Rounds the corners of each card */
   
  }
  .card-body p{
    color: grey;
    line-height: 1.8em;
  }